body#essexAtlasAppRoot .p-datatable .p-datatable-thead > tr > th {
  background-color: transparent;
  border: unset;
  padding: 10px 5px;
  border-bottom: 1px solid #2e2f31;
  color: #2e2f31;
  overflow: visible;
  height: 64px;
  text-align: left;
}

body#essexAtlasAppRoot
  .p-datatable
  .p-component.p-dropdown
  .p-dropdown-trigger {
  background-color: #666666;
}

body#essexAtlasAppRoot .p-paginator {
  background-color: unset;
  border: unset;
  padding-top: 30px;
}

body#essexAtlasAppRoot .p-paginator .p-component.p-dropdown {
  width: unset;
  margin-left: 3em;
}

body#essexAtlasAppRoot .p-datatable .p-datatable-tbody > tr > td {
  height: 43px;
}

body#essexAtlasAppRoot .p-datatable .p-datatable-tbody > tr > td {
  border: unset;
  border-bottom: 1px solid #dfdfdf;
  color: #666666;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 12px;
  line-height: 1;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
}

body#essexAtlasAppRoot .p-datatable .p-datatable-tbody > tr > td .p-inputtext {
  font-size: 12px;
  line-height: 1;

  min-height: 25px;
}

body#essexAtlasAppRoot
  .p-datatable
  .p-paginator
  .p-paginator-pages
  .p-paginator-page.p-highlight {
  background-color: #666666;
}

body#essexAtlasAppRoot .p-component.p-dropdown {
  height: 32px;
  display: inline-flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 100%;
}

body#essexAtlasAppRoot .p-component.p-dropdown .p-dropdown-trigger,
body#essexAtlasAppRoot .p-multiselect .p-multiselect-trigger {
  background-color: #c75109;
  color: white;
}

body#essexAtlasAppRoot .p-component.p-dropdown .p-dropdown-clear-icon {
  right: 2.4em;
  color: #2e2f31;
}

body#essexAtlasAppRoot .p-inputtext {
  min-height: 30px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  color: #2e2f31;
}

body#essexAtlasAppRoot
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item.p-highlight,
body#essexAtlasAppRoot
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item-group.p-highlight {
  background-color: #c75109;
}

body#essexAtlasAppRoot
  .p-datatable
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item.p-highlight,
body#essexAtlasAppRoot
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item-group.p-highlight {
  background-color: #666666;
}

body#essexAtlasAppRoot .p-dropdown-panel {
  border-color: white;
}

body#essexAtlasAppRoot .p-button {
  cursor: pointer;
  background-color: #c75109;
  border-color: #c75109;
}

/* buttons that are not fully colored in (link buttons that appear like links) */
body#essexAtlasAppRoot .essexAtlasLinkButton.p-button {
  background-color: transparent;
  border-color: transparent;
  color: #c75109;
  padding: 0;
  line-height: 1.15;
}

/* remove the padding around link buttons */
body#essexAtlasAppRoot
  .essexAtlasLinkButton.p-button.p-button-icon-only
  .p-button-text {
  padding: 0;
}

/* control / increase the size of the help icon button*/
body#essexAtlasAppRoot
  .essexAtlasHelpDownloadIcon.essexAtlasLinkButton.p-button.p-button-icon-only
  .p-button-icon-left {
  font-size: 30px;
}

body#essexAtlasAppRoot .mainMenuItems .p-button {
  cursor: pointer;
  color: #ccc;
  background-color: transparent;
  border-color: transparent;
  padding-bottom: 16px;
  margin-bottom: -16px;
  padding-top: 16px;
  margin-top: -16px;
  height: unset;
}

body#essexAtlasAppRoot .mainMenuItems .p-button .p-button-text {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 12px;
  display: block;
  margin-left: -1em;
}

body#essexAtlasAppRoot .mainMenuItems .p-menu.p-component {
  background-color: rgba(0, 131, 143);
  border: none;
}

body#essexAtlasAppRoot .mainMenuItems .p-menu.p-component .p-menu-list {
  background-color: rgba(0, 0, 0, 0.5);
}

body#essexAtlasAppRoot
  .mainMenuItems
  .p-menu.p-component
  .p-menu-list
  .p-menuitem-link
  .p-menuitem-text {
  color: #ccc;
  font-size: 12px;
}

body#essexAtlasAppRoot
  .mainMenuItems
  .p-menu.p-component
  .p-menu-list
  .p-menuitem-link:hover {
  background-color: #ccc;
}

body#essexAtlasAppRoot
  .mainMenuItems
  .p-menu.p-component
  .p-menu-list
  .p-menuitem-link:hover
  .p-menuitem-text {
  color: black;
}

body#essexAtlasAppRoot .p-scrollpanel-wrapper {
  /* overflow-y: visible; */
}

/* SelectButton background and color when selection is inactive */
body#essexAtlasAppRoot .p-selectbutton .p-button {
  cursor: pointer;
  color: #ffffff;
  background-color: #666666;
  border-color: #666666;
}

/* SelectButton background and color when selection is active */
body#essexAtlasAppRoot .p-selectbutton .p-button.p-highlight {
  cursor: pointer;
  background-color: #c75109;
  border-color: #c75109;
}

body#essexAtlasAppRoot .p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #2e2f31;
}

body#essexAtlasAppRoot .p-component.p-datatable {
  /* box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2); */
  border-radius: 10px;
  border: unset;
}

body#essexAtlasAppRoot .p-datatable .p-datatable-header,
body#essexAtlasAppRoot .p-datatable .p-datatable-footer {
  background-color: unset;
  border: unset;
}

body#essexAtlasAppRoot .p-datatable .p-datatable-footer {
  padding-top: 18px;
  padding-bottom: 18px;
}

body#essexAtlasAppRoot .p-datatable .p-datatable-tbody > tr.p-highlight {
  background-color: unset;
}

/* Rows on tables highlighted to show property exceptions */
body#essexAtlasAppRoot
  .p-datatable
  .p-datatable-tbody
  > tr.highlightRowForPropertyException {
  background-color: #c7510920;
  color: black;
  font-weight: bold;
}

/* Rows on tables highlighted to show unit exceptions */
body#essexAtlasAppRoot
  .p-datatable
  .p-datatable-tbody
  > tr.highlightRowForUnitException {
  background-color: #ffe39930;
  color: black;
  font-weight: bold;
}

body#essexAtlasAppRoot
  .p-datatable
  .p-datatable-tbody
  > tr.highlightRowForSelection {
  background-color: rgb(189, 213, 221);
}

/* Rows on tables bolded in black to show property exceptions */
body#essexAtlasAppRoot
  .p-datatable
  .p-datatable-tbody
  > tr.highlightRowForPropertyException
  > td {
  color: black;
}

body#essexAtlasAppRoot .p-datatable .p-datatable-tbody > tr > td > button {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
}

/* Hide checkboxes in frozen header rows of the table.
   TODO - T-4989 - Find a better way to accomplish this.
*/
body#essexAtlasAppRoot
  .p-datatable-scrollable-header-box
  .p-datatable-scrollable-header-table
  .p-datatable-tbody
  .p-datatable-row
  .p-checkbox.p-component,
body#essexAtlasAppRoot
  .p-datatable-scrollable-header-box
  .p-datatable-scrollable-header-table
  .p-datatable-tbody
  .p-datatable-row
  .p-row-toggler {
  display: none;
}

/* Background color for frozen rows inside of tables (e.g. review summary screen) */
body#essexAtlasAppRoot
  .p-datatable-scrollable-header-box
  .p-datatable-scrollable-header-table
  .p-datatable-tbody
  .p-datatable-row {
  background-color: #666666;
}

/* Font color for frozen rows inside of tables (e.g. review summary screen) */
body#essexAtlasAppRoot
  .p-datatable-scrollable-header-box
  .p-datatable-scrollable-header-table
  .p-datatable-tbody
  .p-datatable-row
  td {
  font-weight: bold;
  color: white;
}

/* Width for checkbox columns in tables (e.g. on review summary screen */
body#essexAtlasAppRoot .p-datatable .p-selection-column {
  width: 25px;
}

body#essexAtlasAppRoot .p-checkbox .p-checkbox-box.p-highlight {
  background-color: #c75109;
  border-color: #c75109;
}

body#essexAtlasAppRoot .p-datatable .p-checkbox .p-checkbox-box.p-highlight {
  background-color: #00838f;
  border-color: #00838f;
}

body#essexAtlasAppRoot .p-datepicker table td > span.p-highlight {
  background-color: #c75109;
}

body#essexAtlasAppRoot .p-calendar.p-inputwrapper-filled {
  width: 100%;
  display: inline-block;
}

@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: #c75109;
  }
}

body#essexAtlasAppRoot .p-dropdown .p-dropdown-label {
  padding-right: 3em;
}

body#essexAtlasAppRoot .p-tabview .p-tabview-panels {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  border: unset;
  border-radius: unset;
}

body#essexAtlasAppRoot .p-tabview.p-tabview-top .p-tabview-nav li a {
  border: unset;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}

body#essexAtlasAppRoot .p-tabview .p-tabview-nav li {
  padding: 5px 5px 0 5px;
  overflow: hidden;
}

body#essexAtlasAppRoot
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li.p-highlight
  a {
  background-color: #c75109;
}

body#essexAtlasAppRoot
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li:not(.p-highlight):not(.p-disabled):hover
  a {
  background-color: #dfdfdf;
}

body#essexAtlasAppRoot .p-tabview.p-tabview-top > .p-tabview-nav li {
  margin-bottom: 2px;
}

body#essexAtlasAppRoot .p-tooltip {
  z-index: 1;
}

body#essexAtlasAppRoot .p-tooltip.p-component .p-tooltip-arrow,
body#essexAtlasAppRoot .p-tooltip.p-component .p-tooltip-text {
  background-color: red;
  border-right-color: red;
  border-left-color: red;
}

body#essexAtlasAppRoot
  [class*="bestRenewalOffersTable"]
  td[class*="RightAlign"]
  input {
  text-align: right;
}

body#essexAtlasAppRoot .p-inputgroup {
  padding-right: 33px;
}

body#essexAtlasAppRoot .pi-comments {
  font-size: 2.25em;
}

body#essexAtlasAppRoot .p-scrollpanel-bar-y {
  margin-left: 9px;
}

body#essexAtlasAppRoot
  .p-inputswitch.p-inputswitch-checked
  .p-inputswitch-slider {
  background-color: #c75109;
}
