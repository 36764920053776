@import-normalize;

@font-face {
  font-family: "raleway-regular";
  src: local("raleway-regular"),
    url(./fonts/raleway-regular.woff) format("woff");
}

@font-face {
  font-family: "raleway-bold";
  src: local("raleway-bold"), url(./fonts/raleway-bold.woff) format("woff");
}

html,
body {
  height: 100%;
  min-height: 100%;
  width: 100%;
}

body {
  height: 100%;
  min-height: 100vh;
  margin: 0px;
  background-color: #f8f8f8;
  background-repeat: repeat;
  cursor: default;
  overflow-y: scroll;
}

#root {
  height: 100%;
  min-height: 100%;
}
